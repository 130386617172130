import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ headers, body }) => (
  <table>
    <thead>
      <tr>
        {headers.map(item => <td key={item}>{item}</td>)}
      </tr>
    </thead>
    <tbody>
      {body.map(row => (
        <tr key={row}>
          {/* eslint-disable-next-line react/no-danger */}
          {headers.map(
            (item, index) => <td key={[index]} dangerouslySetInnerHTML={{ __html: row[index] }} />,
          )
          }
        </tr>
      ))}
    </tbody>
  </table>
);

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  body: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default Table;
