import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Table from '../components/Table';
import Banner from '../components/Banner';
import Newsletter from '../components/Newsletter';
import Border from '../components/Border';

import newsletterS from '../images/newsletterServices.jpg';
import newsletterL from '../images/newslettersServ-large.jpg';

import '../styles/3_pages/article.scss';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { fields, sys: { contentType: { sys: { id } } } } = node.data.target;

      switch (id) {
        case 'table':
          return <Table headers={fields.headers['en-US']} body={fields.body['en-US']} />;
        case 'banner':
          return <Banner header={fields.header['en-US']} link={fields.link['en-US']} />;
        default:
          return <p>{fields.name}</p>;
      }
    },
  },
};

const ArticlePage = ({ location, data }) => {
  const {
    contentfulArticle: {
      title,
      articleBody,
      category,
      fields: { categoryId },
      metaDescription,
      bannerImage,
    },
  } = data;

  const html = documentToReactComponents(articleBody.json, options);
  const description = metaDescription || articleBody.json.content.find(
    item => item.nodeType === 'paragraph',
  ).content[0].value.slice(0, 120);

  return (
    <Layout location={location}>
      <SEO title={title} description={description} />
      <article className="article">
        <div className="article__wrapper">
          <div className="article__container">
            <div className="article__content">
              <Link to="/news" state={{ scrollTo: categoryId }}>
                {category}
              </Link>
              <img src={bannerImage.fluid.src} alt={title} />
              <h1>{title}</h1>
              {html}
            </div>
          </div>
        </div>
        <Border />
        <Newsletter
          location={location}
          imageSmall={newsletterS}
          imageLarge={newsletterL}
          title="FREE Newsletter "
          paragraph="Subscribe to receive the latest news and offers based on your preferences. Update your preferences to tailor your notifications at any time. "
        />
      </article>
    </Layout>
  );
};

ArticlePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

ArticlePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    contentfulArticle: PropTypes.shape({
      title: PropTypes.string.isRequired,
      fields: PropTypes.shape({
        categoryPath: PropTypes.string.isRequired,
        categoryId: PropTypes.string.isRequired,
      }).isRequired,
      bannerImage: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
        }),
      }).isRequired,
      metaDescription: PropTypes.string,
      articleBody: PropTypes.shape({
        json: PropTypes.shape({
          content: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
      }).isRequired,
      category: PropTypes.string.isRequired,
      brand: PropTypes.string,
    }),
  }).isRequired,
};

export default ArticlePage;

export const query = graphql`
query ArticleBySlug($slug: String!) {
    contentfulArticle(fields: {slug: {eq: $slug}} ) {
      title
      category
      brand
      metaDescription
      fields {
        categoryPath
        categoryId
      }
      articleBody {
        json
      }
      bannerImage {
        fluid {
          src
        }
      }
    }
  }
`;
