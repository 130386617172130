import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './styles.scss';

const Newsletter = ({
  location, imageSmall, imageLarge, title, paragraph, paragraphBold, marginLeft,
}) => (
  <section className="newsletter">
    <div className={`newsletter__image newsletter__image--small ${marginLeft ? 'newsletter__image--margin' : ''}`}>
      <img src={imageSmall} alt="Girl with Ipad" />
    </div>
    <div className="newsletter__container">
      <div className="newsletter__image newsletter__image--large">
        <img src={imageLarge} alt="Girl with Ipad" />
      </div>
      <div className="newsletter__content">
        <h3 className="newsletter__header">
          {title}
        </h3>
        <p className="newsletter__para">
          {paragraph}
        </p>
        <p className="newsletter__para">
          <strong>
            {paragraphBold}
          </strong>
        </p>
        <Link to={`/sign-up/${location.search}`} className="mainButton newsletter__cta">sign up now &gt;</Link>
      </div>
    </div>
  </section>
);

Newsletter.defaultProps = {
  paragraphBold: '',
  marginLeft: false,
};

Newsletter.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  imageSmall: PropTypes.string.isRequired,
  imageLarge: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  paragraphBold: PropTypes.string,
  marginLeft: PropTypes.bool,
};

export default Newsletter;
