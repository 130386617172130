import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './styles.scss';

const Banner = ({ header, link }) => (
  <div className="banner">
    <h5 className="banner__header">
      {header}
    </h5>
    <div className="banner__links">
      {link.map(item => (
        <Link key={item.label} className="banner__link" to="/news" state={{ scrollTo: item.url }}>{item.label}</Link>
      ))}
    </div>
  </div>
);

Banner.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  link: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default Banner;
