import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import mmbArrow from '../../images/mmb-arrow.svg';

const Border = ({ news }) => (
  <div className="container">
    <div className="border">
      <img className={`${news ? 'border__right' : ''}`} src={mmbArrow} alt="Arrow" />
    </div>
  </div>
);

Border.defaultProps = {
  news: false,
};

Border.propTypes = {
  news: PropTypes.bool,
};

export default Border;
